import { Component, createSignal } from 'solid-js'
import Button from '~/components/button/button'
import { IndividualSubscriptionProps } from '~/components/individual-subscription/individual-subscription.interfaces'
import styles from '~/components/individual-subscription/individual-subscription.module.scss'
import ResumeIcon from '~/assets/icons/refresh.svg'
import ModifyIcon from '~/assets/icons/edit-alt.svg'
import CancelIcon from '~/assets/icons/close.svg'
import { getFullDate } from '~/helpers/dates'
import ConfirmationModal from '~/components/confirmation-modal/confirmation-modal'
import { setLoadingState } from '~/services/loading/loading'
import { cancelSubscription, resumeSubscription } from '~/services/stripe/subscription'
import { translations } from '~/translations'
import { useNavigate } from '@solidjs/router'
import SubscriptionSchedule from '~/components/subscription-schedule/subscription-schedule'

const IndividualSubscription: Component<IndividualSubscriptionProps> = ({ subscription, refetch }) => {
  // Change Modal
  const navigate = useNavigate()
  const onChange = () => {
    navigate('/subscribe/')
  }

  // Cancel Modal
  const cancelTitle = translations().subscriptions.indie.cancelConfirmation.title(subscription.plan.title)
  const cancelDescription = translations().subscriptions.indie.cancelConfirmation.description(getFullDate(subscription.periodEnd as Date))
  const [showCancelConfirmation, setCancelConfirmation] = createSignal(false)
  const onConfirmCancel = async () => {
    setCancelConfirmation(true)
  }
  const onSubscriptionCancel = async () => {
    setLoadingState({
      loading: true
    })
    await cancelSubscription(subscription.id)
    refetch()
    setLoadingState({
      loading: false
    })
    setCancelConfirmation(false)
  }

  // Resume Modal
  const resumeTitle = translations().subscriptions.indie.resumeConfirmation.title(subscription.plan.title)
  const resumeDescription = translations().subscriptions.indie.resumeConfirmation.description
  const [showResumeConfirmation, setResumeConfirmation] = createSignal(false)
  const onConfirmResume = () => {
    setResumeConfirmation(true)
  }
  const onSubscriptionResume = async () => {
    setLoadingState({
      loading: true
    })
    await resumeSubscription(subscription.id)
    refetch()
    setLoadingState({
      loading: false
    })
    setResumeConfirmation(false)
  }

  return (
    <>
      <ConfirmationModal
        title={cancelTitle}
        description={cancelDescription}
        open={showCancelConfirmation()}
        setOpen={setCancelConfirmation}
        onConfirm={onSubscriptionCancel}
        confirmIcon={CancelIcon}
        confirmLabel={translations().general.actions.cancel}
        rejectLabel={translations().general.actions.back}
        type='danger'
      />

      <ConfirmationModal
        title={resumeTitle}
        description={resumeDescription}
        open={showResumeConfirmation()}
        setOpen={setResumeConfirmation}
        onConfirm={onSubscriptionResume}
        confirmIcon={ResumeIcon}
        confirmLabel={translations().general.actions.resume}
        rejectLabel={translations().general.actions.back}
      />
      
      <div class={styles.individualSubscription}>
        <div class={styles.label}>
          <div class={styles.brand}>
            {subscription.plan.brand}
          </div>
          <div class={styles.title}>
            {subscription.plan.title}
          </div>
          {subscription.plan.subtitle && (
            <div class={styles.subtitle}>
              {subscription.plan.subtitle}
            </div>
          )}
        </div>

        <SubscriptionSchedule subscription={subscription} />

        {subscription.cancelled && (
          <aside class={styles.controls}>
            <Button
              icon={ResumeIcon}
              style='purple'
              onClick={onConfirmResume}
            >
              {translations().general.actions.resume}
            </Button>
          </aside>
        )}

        {!subscription.cancelled && (
          <aside class={styles.controls}>
            <Button
              icon={ModifyIcon}
              style='purple'
              onClick={onChange}
            >
              {translations().general.actions.edit}
            </Button>
            <Button
              icon={CancelIcon}
              style='negative'
              onClick={onConfirmCancel}
            >
              {translations().general.actions.stop}
            </Button>
          </aside>
        )}
      </div>
    </>
  )
}

export default IndividualSubscription
